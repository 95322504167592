import { api } from "@idecore/lib";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react"
import { BeatLoader } from "react-spinners";

export default function LocationsPage(){
    const [filterUser, setFilterUser] = useState([])
    const [length, setLength] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10)
    const [dataLoaded, setDataLoaded] = useState(false);

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage

    const currentItems = filterUser.slice(indexOfFirstItem, indexOfLastItem)
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

    const { data: users, refetch, isLoading, isFetching } = useQuery({
        queryKey: ["locations"],
        queryFn: async() => {
            try{
                const response = await api.location.lists()
                setFilterUser(response.data.data)
                setLength(response.data.data.length)
                return response.data
            }catch(error: any){
                
            }
        }
    });

    const handleSearch = (value: any) => {
        if(value === "" || value === null){
            setFilterUser(users?.data);
        }else{
            const filtered = users?.data.filter(
                (user: any) =>
                    (
                        user && 
                        user.id &&
                        user.id.toLowerCase().includes(value.toLowerCase())
                    ) ||
                    (
                        user && 
                        user.role_name &&
                        user.role_name.toLowerCase().includes(value.toLowerCase())
                    ) ||
                    (
                        user && 
                        user.name &&
                        user.name.toLowerCase().includes(value.toLowerCase())
                    )||
                    (
                        user && 
                        user.email &&
                        user.email.toLowerCase().includes(value.toLowerCase())
                    )
            );
            setFilterUser(filtered);
            setLength(filtered.length);
        }
        setCurrentPage(1);
    }

    useEffect(() => {
        if(!dataLoaded) return;
        setFilterUser(users);
    },[dataLoaded])

    const rows = currentItems.map((location: any) => (
        <tr key={location.id} className="border-b dark:border-gray-700">
            <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{location.id}</th>
            <td className="px-4 py-3">
                <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{location.code}</span>
            </td>
            <td className="px-4 py-3">{location.name}</td>
            <td className="px-4 py-3">{location.address}</td>
            <td className="px-4 py-3">{location.phone}</td>
        </tr>
    ))

    return (
        <>
            <nav className="mb-3 flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Master
                        </a>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Locations</span>
                        </div>
                    </li>
                </ol>
            </nav>

            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                    <div className="w-full md:w-1/2">
                        <div className="flex items-center">
                            <label className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <input type="text" id="simple-search" onChange={(e) => handleSearch(e.target.value)} autoComplete="off" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" required />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-4 py-3">ID</th>
                                <th scope="col" className="px-4 py-3">CODE</th>
                                <th scope="col" className="px-4 py-3">NAME</th>
                                <th scope="col" className="px-4 py-3">ADDRESS</th>
                                <th scope="col" className="px-4 py-3">PHONE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (isLoading || isFetching)
                                ?   <tr>
                                        <td colSpan={5}>
                                            <div className="flex justify-center">
                                                <BeatLoader color="#FFFFFF" size={8} />
                                            </div>
                                        </td>
                                    </tr>
                                :   rows.length > 0 
                                    ?   rows
                                    :   <tr className="border-b dark:border-gray-700">
                                            <td colSpan={5} className="text-center">Data Is Empty</td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        Showing Page {currentPage} of {" "}
                        <span className="font-semibold text-gray-900 dark:text-white"> {Math.ceil(filterUser.length / itemsPerPage)}</span>
                        {" "} -
                        <span className="font-semibold dark:text-white"> Total {filterUser.length} Data</span>
                    </span>
                    <ul className="inline-flex items-stretch -space-x-px">
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 ${currentPage === 1 ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                        <li>
                            <button 
                                className={`flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 ${currentPage === Math.ceil(filterUser.length / itemsPerPage) || (Math.ceil(filterUser.length) === 0) ? '' : `hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white`} dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400`}
                                onClick={() => paginate(currentPage + 1)}
                                disabled={currentPage === Math.ceil(filterUser.length / itemsPerPage) || (Math.ceil(filterUser.length) === 0)}
                            >
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}