import { api } from "@idecore/lib";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function LoginPage() {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEnter = (e: any) => {
        if (e.key === "Enter") {
          onSubmit();
        }
    };

    useEffect(() => {
        const token = localStorage.getItem("token");

        if(token){
            navigate("/dashboard");
        }
    }, [navigate])

    const onSubmit = async () => {
        try{
            setIsSubmitting(true);

            const response = await api.auth.login({
                "email": email,
                "password": password
            })

            if(response.status == 401){
                toast.error("Invalid Email or Password")

                setIsSubmitting(false);
            }

            localStorage.setItem('token', response.data.data.access_token);
            localStorage.setItem('refresh_token', response.data.data.refresh_token);

            setIsSubmitting(false);

            localStorage.setItem('login', 'yes');
            navigate('/dashboard')
            toast.success("Success Login");
        }catch(error: any){
            if(error.response.status === 401){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }else if(error.response.status === 500){
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                })
            }

            setIsSubmitting(false);
        }
    }
    
    return (
        <section className={"bg-slate-200 dark:bg-gray-900"} onKeyDown={handleEnter}>
            <div className={"flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"}>
                <a href="#" className={"flex items-center mb-6 text-4xl font-bold text-blue-700 dark:text-white"}>
                    IDECORE
                </a>
                <div className={"w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"}>
                    <div className={"p-6 space-y-4 md:space-y-6 sm:p-8"}>
                        <h1 className={"text-lg font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"}>
                            Sign in with your account
                        </h1>
                        <div className={"space-y-4 md:space-y-6"}>
                            <div>
                                <label className={"block mb-2 text-sm font-medium text-gray-900 dark:text-white"}>Your email</label>
                                <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required />
                            </div>
                            <div>
                                <label className={"block mb-2 text-sm font-medium text-gray-900 dark:text-white"}>Password</label>
                                <input type="password" name="password" id="password" onChange={(e) => setPassword(e.target.value)} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                            </div>
                            <div className={"flex items-center justify-between"}>
                                <div className={"flex items-start"}>
                                    {/* <div className={"flex items-center h-5"}>
                                        <input id="remember" aria-describedby="remember" type="checkbox" className={"w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"} required />
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label className={"text-gray-500 dark:text-gray-300"}>Remember me</label>
                                    </div> */}
                                </div>
                                <a href="#" className={"text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"}>Forgot password?</a>
                            </div>
                            <button onClick={onSubmit} className={"w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"}>
                                { isSubmitting 
                                    ?   <BeatLoader
                                            color="#FFFFFF" 
                                            size={8} 
                                        />
                                    : 'Sign in'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}