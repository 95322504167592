import { Outlet, useNavigate } from "react-router-dom";
import Header from "./includes/header";
import Sidebar from "./includes/sidebar";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query"
import { api } from "@idecore/lib";

export default function LayoutDashboard(){
    const navigate = useNavigate()
    const [name, SetName] = useState('')
    const [email, SetEmail] = useState('')

    // useEffect(() => {
    //     const token = localStorage.getItem("token");

    //     if(!token){
    //         navigate("/login");
    //         toast.error("Your session is expired, please login again.");
    //     } 
    // })

    useEffect(() => {
        const isLogin = localStorage.getItem('login');

        if(isLogin == 'yes'){
            localStorage.removeItem('login');
            navigate(0);
        }
    })

    useEffect(() => {
        const fetchMe = async() => {
            try{
                const response = await api.auth.me()
                console.log(response.data.data.email)
                SetName(response.data.data.name)
                SetEmail(response.data.data.email)
            }catch(error: any){
                console.error(error)

                if(error.response.status == 401){
                    console.log("ERROR 401 NI")
                    // localStorage.removeItem("token");
                    // localStorage.removeItem("refresh_token");
                    // navigate("/login");
                    // toast.error("Your session is expired, please login again.");
                }
            }
        }
        console.log("RUNNING")
        fetchMe()
    },[])

    

    // const { data, refetch, isLoading } = useQuery({
    //     queryKey: ["users"],
    //     queryFn: async() => {
    //         try{
    //             const response = await api.auth.me()

    //             return response.data
    //         }catch(error: any){
    //             console.log(error)
    //         }
    //     }
    // });

    return (
        <div className="bg-slate-100 dark:bg-gray-900">
            <Header 
                name={name}
                email={email}
            />
            <Sidebar />
            <main className="p-4 min-h-screen md:ml-64 pt-20">
                <Outlet />
            </main>
        </div>
    )
}