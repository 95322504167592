import '../css/app.css';
import 'flowbite';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState } from 'react';
import LoginPage from './pages/auth/login';
import { IconHome } from '@tabler/icons-react';
import { DashboardPage } from './pages/dashboard/home';
import LayoutDashboard from './layouts/dashboard';
import SettingsPage from './pages/dashboard/settings';
import UsersPage from './pages/dashboard/master/users';
import DevicesPage from './pages/dashboard/master/devices';
import LocationsPage from './pages/dashboard/master/locations';

// const routes = [
//     {
//         name: "Main Dashboard",
//         layout: "/admin",
//         directLink: "home",
//         icon: <IconHome className="h-6 w-6" />,
//         component: <ProtectedRoutes element={<DashboardPage />} />,
//     },
// ]

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

function AppRoutes() {
    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route element={<LayoutDashboard />}>
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/master/locations" element={<LocationsPage />} />
                    <Route path="/master/users" element={<UsersPage />} />
                    <Route path="/master/devices" element={<DevicesPage />} />
                    <Route path="/settings" element={<SettingsPage />} />
                </Route>
            </Routes>
        </QueryClientProvider>
    )
}

export default AppRoutes;