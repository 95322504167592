import LineChart from "@idecore/components/charts/line";
import TotalUserPieChart from "@idecore/components/charts/pie";
import { api } from "@idecore/lib";
import { IconHome2, IconLocation, IconRouter, IconUser } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { RiseLoader } from "react-spinners";

export function DashboardPage(){
    const navigate = useNavigate();

    const { data: summarize, refetch, isLoading, isFetching } = useQuery({
        queryKey: ["summarize"],
        queryFn: async() => {
            try{
                const response = await api.dashboard.summarize()

                return response
            }catch(error: any){
                console.error(error)
            }
        }
    });

    return (
        <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                <div
                    className="bg-white border-2 rounded-lg border-gray-300 dark:border-gray-600 h-24 md:h-24"
                >
                    
                    {
                        isLoading || isFetching 
                        ?   <div className="flex items-center justify-center h-[90px]">
                                <RiseLoader
                                    color="#0F70CCFF" 
                                    size={8} 
                                />
                            </div>
                        :   <>
                                <div 
                                    className="ml-[18px] flex h-[90px] w-auto flex-row items-center cursor-pointer"
                                >
                                    <div className="rounded-full bg-blue-200 p-3 dark:bg-navy-700">
                                        <span className="flex items-center text-blue-500 dark:text-white">
                                            <IconRouter />
                                        </span>
                                    </div>

                                    <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                                        <p className="font-dm text-sm font-medium text-gray-600">Total OLT</p>
                                        <h4 className="text-xl font-bold text-blue-700 dark:text-white">
                                            {summarize?.data?.data?.total_olt}
                                        </h4>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <div
                    className="bg-white border-2 rounded-lg border-gray-300 dark:border-gray-600 h-24 md:h-24"
                >
                    
                    {
                        isLoading || isFetching 
                        ?   <div className="flex items-center justify-center h-[90px]">
                                <RiseLoader
                                    color="#0F70CCFF" 
                                    size={8} 
                                />
                            </div>
                        :   <>
                                <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center ">
                                    <div className="rounded-full bg-blue-200 p-3 dark:bg-navy-700">
                                        <span className="flex items-center text-blue-500 dark:text-white">
                                            <IconRouter />
                                        </span>
                                    </div>

                                    <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                                        <p className="font-dm text-sm font-medium text-gray-600">Total ODP</p>
                                        <h4 className="text-xl font-bold text-blue-700 dark:text-white">
                                            {summarize?.data?.data?.total_odp}
                                        </h4>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <div
                    className="bg-white border-2 rounded-lg border-gray-300 dark:border-gray-600 h-24 md:h-24"
                >
                    
                    {
                        isLoading || isFetching 
                        ?   <div className="flex items-center justify-center h-[90px]">
                                <RiseLoader
                                    color="#0F70CCFF" 
                                    size={8} 
                                />
                            </div>
                        :   <>
                                <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center ">
                                    <div className="rounded-full bg-blue-200 p-3 dark:bg-navy-700">
                                        <span className="flex items-center text-blue-500 dark:text-white">
                                            <IconRouter />
                                        </span>
                                    </div>

                                    <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                                        <p className="font-dm text-sm font-medium text-gray-600">Total ODC</p>
                                        <h4 className="text-xl font-bold text-blue-700 dark:text-white">
                                            {summarize?.data?.data?.total_odc}
                                        </h4>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <div
                    className="bg-white border-2 rounded-lg border-gray-300 dark:border-gray-600 h-24 md:h-24"
                >
                    
                    {
                        isLoading || isFetching 
                        ?   <div className="flex items-center justify-center h-[90px]">
                                <RiseLoader
                                    color="#0F70CCFF" 
                                    size={8} 
                                />
                            </div>
                        :   <>
                                <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center ">
                                    <div className="rounded-full bg-blue-200 p-3 dark:bg-navy-700">
                                        <span className="flex items-center text-blue-500 dark:text-white">
                                            <IconRouter />
                                        </span>
                                    </div>

                                    <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                                        <p className="font-dm text-sm font-medium text-gray-600">Total ONT</p>
                                        <h4 className="text-xl font-bold text-blue-700 dark:text-white">
                                            {summarize?.data?.data?.total_ont}
                                        </h4>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                <div
                    className="bg-white border-2 rounded-lg border-gray-300 hover:border-blue-700 dark:border-gray-600 h-24 md:h-24"
                >
                    
                    {
                        isLoading || isFetching 
                        ?   <div className="flex items-center justify-center h-[90px]">
                                <RiseLoader
                                    color="#0F70CCFF" 
                                    size={8} 
                                />
                            </div>
                        :   <>
                                <div 
                                    className="ml-[18px] flex h-[90px] w-auto flex-row items-center cursor-pointer"
                                    onClick={() => navigate('/master/locations')}
                                >
                                    <div className="rounded-full bg-blue-200 p-3 dark:bg-navy-700">
                                        <span className="flex items-center text-blue-500 dark:text-white">
                                            <IconHome2 />
                                        </span>
                                    </div>

                                    <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                                        <p className="font-dm text-sm font-medium text-gray-600">Total Locations</p>
                                        <h4 className="text-xl font-bold text-blue-700 dark:text-white">
                                            {summarize?.data?.data?.total_locations}
                                        </h4>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                <div
                    className="bg-white border-2 rounded-lg border-gray-300 hover:border-blue-700 dark:border-gray-600 h-24 md:h-24"
                >
                    
                    {
                        isLoading || isFetching 
                        ?   <div className="flex items-center justify-center h-[90px]">
                                <RiseLoader
                                    color="#0F70CCFF" 
                                    size={8} 
                                />
                            </div>
                        :   <>
                                <div 
                                    className="ml-[18px] flex h-[90px] w-auto flex-row items-center cursor-pointer"
                                    onClick={() => navigate('/master/users')}
                                >
                                    <div className="rounded-full bg-blue-200 p-3 dark:bg-navy-700">
                                        <span className="flex items-center text-blue-500 dark:text-white">
                                            <IconUser />
                                        </span>
                                    </div>

                                    <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                                        <p className="font-dm text-sm font-medium text-gray-600">Total Users</p>
                                        <h4 className="text-xl font-bold text-blue-700 dark:text-white">
                                            {summarize?.data?.data?.total_users}
                                        </h4>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                {/* <div
                    className="bg-white text-gray-400 border-2 rounded-lg border-gray-300 dark:border-gray-600 h-24 md:h-24"
                >

                </div>
                <div
                    className="bg-white text-gray-400 border-2 rounded-lg border-gray-300 dark:border-gray-600 h-24 md:h-24"
                >

                </div> */}
            </div>
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                <div
                    className="flex justify-center bg-white border-2 rounded-lg dark:border-gray-600 h-32 md:h-64"
                ><LineChart /></div>
                <div
                    className="flex justify-center bg-white border-2 rounded-lg dark:border-gray-600 h-32 md:h-64"
                ><TotalUserPieChart /></div>
            </div> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                {/* <div
                    className="flex justify-center bg-white border-2 rounded-lg dark:border-gray-600 h-32 md:h-64"
                ><TotalUserPieChart /></div> */}
                {/* <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64"
                ></div> */}
            </div>
            {/* <div className="grid grid-cols-2 gap-4 mb-4">
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
            </div>
            <div
                className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-96 mb-4"
            ></div>
            <div className="grid grid-cols-2 gap-4">
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
                <div
                    className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"
                ></div>
            </div> */}
        </>
    );
}