import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './routes';
import { ToastContainer } from 'react-toastify';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
<BrowserRouter future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
        }}>
        <App />
        <ToastContainer />
    </BrowserRouter>
);