import axios from '@idecore/lib/axios';

export async function lists() {
    return axios.get('/api/v1/users')
        .catch(function (error) {
            return Promise.reject(error)
        })
}

export async function store(data: any) {
    console.log(data);
    return axios.post('/api/v1/users', data)
        .catch(function (error) {
            return Promise.reject(error)
        })
}

export async function update(data: any) {
    console.log(data);
    return axios.put('/api/v1/users/' + data.id, data)
        .catch(function (error) {
            return Promise.reject(error)
        })
}

export async function destroy(param: any) {
    console.log("DELETE " + param.id);
    return axios.delete('/api/v1/users/' + param.id)
        .catch(function (error) {
            return Promise.reject(error)
        })
}