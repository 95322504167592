import Axios from 'axios';

const baseURL = import.meta.env?.VITE_API_URL;
const apiSecret = import.meta.env?.VITE_API_SECRET;

console.log("AXIOS : " + localStorage.getItem('token'))

export default Axios.create({
    baseURL: baseURL,
    withCredentials: false,
    headers: {
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Headers": "X-Secret-Key",
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'X-Secret-Key': apiSecret,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
});